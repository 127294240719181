import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "col-md-6 col-xxl-4" }
const _hoisted_2 = { class: "card-body d-flex flex-center flex-column p-9" }
const _hoisted_3 = {
  key: 0,
  class: "symbol symbol-65px symbol-circle mb-5"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-white h-15px w-15px ms-n3 mt-n3"
}
const _hoisted_6 = {
  key: 1,
  class: "symbol symbol-65px symbol-circle mb-5"
}
const _hoisted_7 = {
  key: 0,
  class: "bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-white h-15px w-15px ms-n3 mt-n3"
}
const _hoisted_8 = {
  href: "#",
  class: "fs-4 text-gray-800 text-hover-primary fw-bolder mb-0"
}
const _hoisted_9 = { class: "fw-bold text-gray-400 mb-6" }
const _hoisted_10 = { class: "d-flex flex-center flex-wrap mb-5" }
const _hoisted_11 = { class: "border border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3" }
const _hoisted_12 = { class: "fs-6 fw-bolder text-gray-700" }
const _hoisted_13 = { class: "border border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3" }
const _hoisted_14 = { class: "fs-6 fw-bolder text-gray-700" }
const _hoisted_15 = {
  key: 2,
  href: "#",
  class: "btn btn-sm btn-light-primary"
}
const _hoisted_16 = { class: "svg-icon svg-icon-3" }
const _hoisted_17 = {
  key: 3,
  href: "#",
  class: "btn btn-sm btn-light"
}
const _hoisted_18 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["card", _ctx.cardClasses])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.avatar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.avatar,
                alt: "image"
              }, null, 8, _hoisted_4),
              (_ctx.online)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", {
                class: _normalizeClass([`text-${_ctx.color} bg-light-${_ctx.color}`, "symbol-label fs-2x fw-bold"])
              }, _toDisplayString(_ctx.initials), 3),
              (_ctx.online)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                : _createCommentVNode("", true)
            ])),
        _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.position), 1),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.avgEarnings), 1),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fw-bold text-gray-400" }, "Avg. Earnings", -1))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.totalSales), 1),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fw-bold text-gray-400" }, "Total Sales", -1))
          ])
        ]),
        (_ctx.connected)
          ? (_openBlock(), _createElementBlock("a", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr012.svg" })
              ]),
              _cache[2] || (_cache[2] = _createTextVNode(" Connected "))
            ]))
          : (_openBlock(), _createElementBlock("a", _hoisted_17, [
              _createElementVNode("span", _hoisted_18, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _cache[3] || (_cache[3] = _createTextVNode(" Connect "))
            ]))
      ])
    ], 2)
  ]))
}