
import { defineComponent, onMounted } from "vue";
import Card3 from "@/components/cards/Card3.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "connections",
  components: {
    Card3,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Connections", ["Pages", "Profile"]);
    });
  },
});
