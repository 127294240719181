import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-6 mb-6 g-xl-9 mb-xl-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card3 = _resolveComponent("Card3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex flex-wrap flex-stack mb-6\"><h3 class=\"fw-bolder my-2\"> My Connections <span class=\"fs-6 text-gray-400 fw-bold ms-1\">(29)</span></h3><div class=\"d-flex my-2\"><select name=\"status\" data-control=\"select2\" data-hide-search=\"true\" class=\"form-select form-select-white form-select-sm w-125px\"><option value=\"Active\" selected>Active</option><option value=\"Approved\">In Progress</option><option value=\"Declined\">To Do</option><option value=\"In Progress\">Completed</option></select></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card3, {
        name: "Patric Watson",
        position: "Art Director at Novica Co.",
        online: true,
        avatar: "media/avatars/300-11.jpg",
        connected: true,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Olivia Larson",
        position: "Art Director at Seal Inc.",
        online: false,
        avatar: "media/avatars/300-6.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Adam Williams",
        position: "System Arcitect at Wolto Co.",
        online: true,
        initials: "A",
        color: "warning",
        connected: true,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Paul Marcus",
        position: "Art Director at Novica Co.",
        online: true,
        initials: "P",
        color: "primary",
        connected: true,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Neil Owen",
        position: "Accountant at Numbers Co.",
        online: false,
        initials: "N",
        color: "success",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Sean Paul",
        position: "Developer at Loop Inc",
        online: true,
        initials: "S",
        color: "info",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Kitona Johnson",
        position: "Web Designer at Nextop Ltd.",
        online: false,
        avatar: "media/avatars/300-1.jpg",
        connected: true,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Robert Doe",
        position: "Marketing Analytic at Avito Ltd.",
        online: false,
        avatar: "media/avatars/300-14.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Soul Jacob",
        position: "Art Director at Novica Co.",
        online: false,
        avatar: "media/avatars/300-12.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Nina Strong",
        position: "CTO at Kilp Ltd.",
        online: true,
        avatar: "media/avatars/300-7.jpg",
        connected: true,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Patric Watson",
        position: "Art Director at Novica Co.",
        online: true,
        avatar: "media/avatars/300-11.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card3, {
        name: "Olivia Larson",
        position: "Art Director at Seal Inc.",
        online: false,
        avatar: "media/avatars/300-6.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      })
    ])
  ], 64))
}